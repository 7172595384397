export default {
    navs: {
        client: "Customer Dashboard"
    },
    page_change: {
        loading: "Loading page"
    },
    index: {
        rede_section: {
            title: "Network Structure",
            subTitle: "Map of some locations of our 296 Tbps mitigation and connection points on our network, delivering our users an unparalleled connection and exemplary mitigation.",
            help: {
                "datacenter": {
                    title: "",
                    description: "Datacenter",
                },
                "pop": {
                    title: "",
                    description: "Connection point/Traffic exchange",
                },
                "msr": {
                    title: "",
                    description: "Traffic Cleaning Center (Anti-DDoS)",
                }
            }
        },
        differential_section: {
            title: "Our differences"
        },
        clients_section: {
            title: "Our clients"
        }
    },
    infrastructure: {
        tabs: {
            datacenter: "Data Center",
            colocation: "Colocation",
            network: "Rede",
            anti_ddos: "Anti-DDoS"
        },
        datacenter_section: {
            title: "Data Center",
            subTitle: "Structure located in Cotia-SP, with several modern features and certifications in order to deliver a great experience to our customers.",
            resources :{
                team: "Specialized security team and monitoring 24/7/365",
                redundancy: "Total operation redundancy (Energy, Cooling, Fibers and Equipment).",
                sla: "100% SLA on energy, maintained with UPS and generators capable of guaranteeing operation for more than 24 hours without energy.",
                environment: "Controlled environment at 25ºC and 30% humidity.",
                anti_fire: "Fire system with HFC-125 gas, ensuring the containment of any fire without damaging the equipment.",
                certifications: "ISO/IEC27017 and ISO/IEC 27001 certifications."
            }
        },
        network_section: {
            title: "Rede",
            subTitle: "Have the best ping and stability in Brazil, ensuring all security and stability, in addition to a protected network.",
            resources :{
                ix_br: "Connection with the main Brazilian traffic exchange point (IX.br São Paulo)",
                tier_1: "Connection with the main Carrier Tier 1 in the national and international market.",
                ddos_alwaysOn: "Anti-DDoS Always On system, ensuring maximum protection against volumetric attacks, without the use of blackhole/null route or route change.",
                monitor: "Constant traffic monitoring.",
                manrs: "Best Practice Certifications for Internet Security.",
                devices: "Equipment from the best brands on the market.",
                connections: "Connection with other national exchange points, in addition to peering agreements with Cloud and CDN providers."
            }
        },
        anti_ddos_section: {
            title: "Anti-DDoS",
            subTitle: "No route changes, no ping boost or delay to mitigate your attack, with 296 Tbps of capacity.",
            map: "Traffic Clearing Centers",
            resources: {
                always_on: "Always active mitigation, ensuring the best response time against any threat.",
                team: "Monitoring and dedicated team to solve any situation.",
                mitigation_mode: "Mitigation of attacks close to the origin, thus ensuring that there is no resource exhaustion at the destination.",
                servers: "All services in Brazil include DDoS mitigation.",
                vendor: "Multi vendor solution, always ensuring the best approach to traffic cleaning.",
                null_route: "We do not use null route/blackhole or re-route techniques.",
                ddos_pro: "In case of zero-day attacks, application attacks, among other more complex levels, we have the DDoS Pro solution, ensuring with its artificial intelligence an immediate response to any type of threat, whether an attack never seen before, or something impossible to block by common means.",
                last_year_block: "Attacks blocked in 2022",
                last_year_block_regions: "By Region",
                last_year_block_types: "By type",
                biggest_last_year_block: "The Biggest Attacks",
                total_last_year_block: "Total mitigado",
                major_attack_mitigated: "Maior ataque já mitigado: 2.7Tb/s (pico inicial)"
            },
            ddos_pro: {
                title: "DDoS Pro",
                subTitle: "Contact us to find out how to hire.",
                resources: {
                    ai: "Artificial intelligence constantly analyzing all traffic to identify any possible DDoS early on with less than 100ms to initiate an action.",
                    learning: "Analysis of the entire data stream to identify complex and undetectable attacks.",
                    painel: "Customer access panel with report of every incident and all data.",
                    port_learning: "Port analysis in isolation, generating specific mitigations for all different types of traffic that each service uses.",
                    zero_trust: "Zero Trust Policies",
                    threatstop: "Daily updates with global anomaly analysis system and attack vectors."
                }
            }
        }
    },
    colocation: {
        title: "Colocation",
        subTitle: "Structure assembled with all the dedication, quality and commitment. We are providing the structure that you would build for your company, so you can be sure that your server will be in safe hands.",
        resources :{
            redundancy: "Full redundancy of equipment to ensure your entire operation runs 24/7/365 without fail.",
            diferencial: "Datacenter designed to offer all that is best to our customers, structure built with zero compromises in mind, ensuring the best stability and security.",
            types: "Possibility of colocation ranging from 1U to Cage Rack."
        },
        contact: "Contact us to request a quote."
    },
    footer: {
        certifications: {
            title: "Certifications"
        },
        links: {
            title: "Useful links"
        },
        social: {
            title: "Social"
        },
        contato: {
            title: "Contact"
        },
        status: {
            ok: "All systems are operational.",
            warning: "Partial interruption in systems.",
            danger: "Major disruption in systems.",
            maintenance: "A maintenance is in progress.",
            scheduled_maintenance: "There is maintenance scheduled for "
        },
        copy: "All rights reserved"
    },
    products: {
        new: "New",
        pre_venda: "Pre sale",
        em_breve: "Em breve",
        unlimit: "Unlimited",
        none: "No products found",
        tag_search: {
            category: "Category",
            memory: "RAM",
            disk: "Disco",
            cpu: "CPU",
            brand: "Marca"
        },
        tag: {
            essential: "Essential",
            hosting: "Hosting",
            game: "Games",
            company: "Companies",
            all: "All"
        },
        regions: "Região do servidor",
        stock: {
            out: "Out of stock",
            last: "Latest units",
            last_one: "Last unit"
        },
        configs: {
            title: "Settings"
        },
        addons: {
            title: "Addons"
        },
        faqs: {
            title: "FAQS",
            tabs: {
                traffic: "Trafego",
                ips: "IPs",
                mitigation: "Mitigação",
                support: "Suporte"
            }
        },
        price: "Price R$ ",
        buy: "Hire",
        tables: {
            cpu: "CPU",
            ram: "RAM",
            disk: "Disk",
            trafego: "Traffic",
            port: "Connection"
        },
        outStockAll: {
            title: "Out of stock.",
            subTitle: "See our other products."
        }
    },
    ping_test: {
        rtt_medio: "Medium ping ",
        addr_test: "Test address"
    },
    error: {
        404: "Page not found",
        500: "HTTP 500 - Server-side error occurred."
    },
    legal: {
        view: {
            terms: "See General Terms",
            policy: "See policies"
        },
        title: {
            terms: "General Terms",
            policy: "Policies"
        },
        description: {
            terms: "Terms of use and privacy policy.",
            policy: "Terms of use and privacy policy."
        }
    },
    blocked: {
        title: "You are blocked",
    }
};